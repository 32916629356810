import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { fetchComponents } from '../../../../entities/component/api/componentsSlice'
import { fetchCreateGetGroups } from '../../slices/group/CreateGroupSlice'
import { fetchGetGroups } from '../../slices/group/getGroupSlice'

export const createGroupAndSeeNewComponentSlice =
    (someData: string) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchCreateGetGroups(someData)).unwrap()
            await dispatch(fetchComponents()).unwrap()
            await dispatch(fetchGetGroups()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }
