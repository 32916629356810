import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { objectComponents } from '../model'
import { RootState } from './../../../app/redux/store'

type objectComponentUpdate = Omit<objectComponents, 'createdAt' | 'updatedAt'>
interface updateComponent {
    dataUpdateComponent: number[]
    loadingUpdateComponent: boolean
    errorUpdateComponent: string | null
}

const initialState: updateComponent = {
    dataUpdateComponent: [],
    loadingUpdateComponent: false,
    errorUpdateComponent: null,
}

export const fetchUpdateComponents = createAsyncThunk(
    'update/updateObject',
    async (data: objectComponentUpdate, { rejectWithValue }) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/component/update?id=${data.id}`,
                {
                    ...data,
                }
            )

            return response.data
        } catch (errorUpdateComponent: any) {
            return rejectWithValue(errorUpdateComponent.message)
        }
    }
)

export const componentUpdateSlice = createSlice({
    name: 'components',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateComponents.pending, (state) => {
                state.loadingUpdateComponent = true
                state.errorUpdateComponent = null
            })
            .addCase(
                fetchUpdateComponents.fulfilled,
                (state, action: PayloadAction<number[]>) => {
                    state.loadingUpdateComponent = false
                    state.dataUpdateComponent = action.payload
                }
            )
            .addCase(
                fetchUpdateComponents.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingUpdateComponent = false
                    state.errorUpdateComponent = action.payload
                }
            )
    },
})
export const loadingUpdateComponent = (state: RootState) =>
    state.componentUpdateSlice.loadingUpdateComponent
export const dataUpdateComponent = (state: RootState) =>
    state.componentUpdateSlice.dataUpdateComponent
export const errorUpdateComponent = (state: RootState) =>
    state.componentUpdateSlice.errorUpdateComponent

export default componentUpdateSlice.reducer
