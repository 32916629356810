import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/redux/store'
import { GeoProps } from '../../../shared/types/types'

interface GeoLocal {
    geoCurrent: GeoProps
}

const initialState: GeoLocal = {
    geoCurrent: { id: 0, geo: '', createdAt: '', updatedAt: '' },
}

export const geoCurrentSlice = createSlice({
    name: 'geoCurrent',
    initialState,
    reducers: {
        setCurrentGeo: (state, action: PayloadAction<GeoProps>) => {
            state.geoCurrent = action.payload
        },
        setDeleteCurrentGeo: (state) => {
            state.geoCurrent = { id: 0, geo: '', createdAt: '', updatedAt: '' }
        },
    },
})
export const { setCurrentGeo, setDeleteCurrentGeo } = geoCurrentSlice.actions
export const geoCurrent = (state: RootState) => state.geoCurrentSlice.geoCurrent
export default geoCurrentSlice.reducer
