import { CardTemp } from '../../../widgets/CardTemp'
const CardTempPage = () => {
	return (
		<div>
			<CardTemp />
			{/* for test */}
		</div>
	)
}

export default CardTempPage
