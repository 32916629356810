import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './../../../app/redux/store'
//import { RootState } from '../../../';

const initialState: any = {
    stateOpen: false,
}

export const isOpenEditorSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCurrentEditorOpen: (state, action: PayloadAction<boolean>) => {
            state.stateOpen = action.payload
        },
    },
})

export const { setCurrentEditorOpen } = isOpenEditorSlice.actions
export const stateOpen = (state: RootState) => state.isOpenEditorSlice.stateOpen

export default isOpenEditorSlice.reducer
