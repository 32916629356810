import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { objectComponentCreate } from '../model'
import { fetchCreateComponent } from './componentCreateSlice'
import { fetchComponents } from './componentsSlice'

export const createAndSeeNewComponentSlice =
    (someData: objectComponentCreate) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchCreateComponent(someData)).unwrap()
            await dispatch(fetchComponents()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }
