import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface currentCompTemp {
    idComponents: number[]
    idTemplate: number
    content: string
}
interface CompTempProps {
    tempData: currentCompTemp
}
const initialState: CompTempProps = {
    tempData: {
        idTemplate: 0,
        content: '',
        idComponents: [],
    },
}

export const currentComponentsSlice = createSlice({
    name: 'dataTempComp',
    initialState,
    reducers: {
        setTempData: (state, action: PayloadAction<currentCompTemp>) => {
            state.tempData = action.payload
        },
    },
})

export const { setTempData } = currentComponentsSlice.actions

export default currentComponentsSlice.reducer
