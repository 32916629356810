import { Modal as BaseModal } from '@mui/base/Modal'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { css, styled } from '@mui/system'
import clsx from 'clsx'
import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { dataComponents } from '../../entities/component/api/componentsSlice'

interface CartProps {
    html: string
    id: number
    name: string
}
interface links {
    styleLinks: string[]
    scriptLinks: string[]
}

const BasicCard: React.FC<CartProps> = ({ html, id, name }) => {
    const [open, setOpen] = useState<boolean>(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [localHtml, setLocalHtml] = useState<string>('')
    const dataComponent = useSelector(dataComponents)
    const [links, setLinks] = useState<links>({
        styleLinks: [],
        scriptLinks: [],
    })

    React.useEffect(() => {
        dataComponent.map((el: any) => {
            if (el.id === id) {
                setLinks(el.links)
            }
        })
    }, [dataComponent])

    React.useEffect(() => {
        let contentWithLinks = '<div>'
        links.styleLinks.forEach((link) => {
            contentWithLinks += `<link rel="stylesheet" href="${link}">`
        })
        links.scriptLinks.forEach((link) => {
            contentWithLinks += `<script src="${link}"></script>`
        })
        contentWithLinks += html
        contentWithLinks += '</div>'

        setLocalHtml(contentWithLinks)
    }, [html, links])

    return (
        <Card sx={{ minWidth: 275, margin: 1 }}>
            <CardContent>
                <Typography variant='h5' component='div'>
                    {name}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={handleOpen} size='small'>
                    увидеть превью
                </Button>
            </CardActions>
            <Modal
                aria-labelledby='unstyled-modal-title'
                aria-describedby='unstyled-modal-description'
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}>
                <ModalContent>
                    <h2 id='unstyled-modal-title' className='modal-title'>
                        {name}
                    </h2>
                    <iframe
                        srcDoc={localHtml} // Используйте srcDoc для вставки HTML-кода в iframe
                        style={{
                            width: '80vw',
                            height: '80vh',
                            border: 'none',
                        }} // Размер iframe
                        title='Preview'
                    />
                </ModalContent>
            </Modal>
        </Card>
    )
}

export default BasicCard

const Backdrop = React.forwardRef<
    HTMLDivElement,
    { open?: boolean; className: string }
>((props, ref) => {
    const { open, className, ...other } = props
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    )
})

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
}

const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
`

const ModalContent = styled('div')(
    ({ theme }) => css`
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 500;
        text-align: start;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
        background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border-radius: 8px;
        border: 1px solid
            ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0 4px 12px
            ${theme.palette.mode === 'dark'
                ? 'rgb(0 0 0 / 0.5)'
                : 'rgb(0 0 0 / 0.2)'};
        padding: 24px;
        color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

        & .modal-title {
            margin: 0;
            line-height: 1.5rem;
            margin-bottom: 8px;
        }

        & .modal-description {
            margin: 0;
            line-height: 1.5rem;
            font-weight: 400;
            color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
            margin-bottom: 4px;
        }
    `
)

const TriggerButton = styled('button')(
    ({ theme }) => css`
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 8px;
        transition: all 150ms ease;
        cursor: pointer;
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid
            ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

        &:hover {
            background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
            border-color: ${theme.palette.mode === 'dark'
                ? grey[600]
                : grey[300]};
        }

        &:active {
            background: ${theme.palette.mode === 'dark'
                ? grey[700]
                : grey[100]};
        }

        &:focus-visible {
            box-shadow: 0 0 0 4px
                ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
            outline: none;
        }
    `
)
