import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { GroupProps } from '../../../../shared/types/types'
import { RootState } from '../../store'
interface componentProps {
    dataGroup: GroupProps
    loadingCreateGroup: boolean
    errorCreateGroup: string | null
}
const initialState: componentProps = {
    dataGroup: {
        id: 0,
        nameGroup: '',
        createdAt: '',
        updatedAt: '',
    },
    loadingCreateGroup: true,
    errorCreateGroup: null,
}
export const fetchCreateGetGroups = createAsyncThunk(
    'group/createGroup',
    async (name: string, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/componentGroup/create`,
                {
                    name,
                }
            )

            // Transform the response data to match GroupProps
            const transformedData = {
                id: response.data.id,
                nameGroup: response.data.name, // Transforming 'name' to 'nameGroup'
                createdAt: response.data.createdAt,
                updatedAt: response.data.updatedAt,
            }

            return transformedData
        } catch (errorUpdateComponent: any) {
            return rejectWithValue(errorUpdateComponent.message)
        }
    }
)
export const groupCreateSlice = createSlice({
    name: 'groupCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreateGetGroups.pending, (state) => {
                state.loadingCreateGroup = true
                state.errorCreateGroup = null
            })
            .addCase(
                fetchCreateGetGroups.fulfilled,
                (state, action: PayloadAction<GroupProps>) => {
                    state.loadingCreateGroup = false
                    state.dataGroup = action.payload
                }
            )
            .addCase(
                fetchCreateGetGroups.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingCreateGroup = false
                    state.errorCreateGroup = action.payload
                }
            )
    },
})
export const loadingCreateGroup = (state: RootState) =>
    state.groupCreateSlice.loadingCreateGroup
export const dataGroup = (state: RootState) => state.groupCreateSlice.dataGroup
export const errorCreateGroup = (state: RootState) =>
    state.groupCreateSlice.errorCreateGroup

export default groupCreateSlice.reducer
