import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { currentGroupSelectedComponentProps } from '../../../../shared/types/types'
import { RootState } from '../../store'

const initialState: currentGroupSelectedComponentProps = {
    idComponent: 0,
    idGroup: 0,
}

export const currentGroupSelectedComponentSlice = createSlice({
    name: 'groupComponentId',
    initialState,
    reducers: {
        setCurrentIdGroupComponent: (
            state,
            action: PayloadAction<currentGroupSelectedComponentProps>
        ) => {
            state.idComponent = action.payload.idComponent
            state.idGroup = action.payload.idGroup
        },
    },
})

export const { setCurrentIdGroupComponent } =
    currentGroupSelectedComponentSlice.actions
export const currentGroupComponent = (state: RootState) =>
    state.currentGroupSelectedComponentSlice
export default currentGroupSelectedComponentSlice.reducer
