import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from './../../../app/redux/store'

interface updateComponent {
    loadingDeleteComponent: boolean
    errorDeleteComponent: string | null
}

const initialState: updateComponent = {
    loadingDeleteComponent: false,
    errorDeleteComponent: null,
}

export const fetchDeleteComponent = createAsyncThunk(
    'component/deleteObject',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/component/delete?id=${id}`
            )

            return response.data
        } catch (errorDeleteComponent: any) {
            return rejectWithValue(errorDeleteComponent.message)
        }
    }
)

export const componentDeleteSlice = createSlice({
    name: 'componentDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteComponent.pending, (state) => {
                state.loadingDeleteComponent = true
                state.errorDeleteComponent = null
            })
            .addCase(
                fetchDeleteComponent.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingDeleteComponent = false
                }
            )
            .addCase(
                fetchDeleteComponent.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingDeleteComponent = false
                    state.errorDeleteComponent = action.payload
                }
            )
    },
})
export const loadingDeleteComponent = (state: RootState) =>
    state.componentDeleteSlice.loadingDeleteComponent
export const errorCreateComponent = (state: RootState) =>
    state.componentDeleteSlice.errorDeleteComponent

export default componentDeleteSlice.reducer
