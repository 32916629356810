import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../store'

interface FormData {
	id: number
	html: string
	name: string
	group: number
	links: {
		styleLinks: string[]
		scriptLinks: string[]
	}
	createdAt: string
	updatedAt: string
}

interface ComponentState {
	components: FormData[]
	errorOneComponent: null | string
	loadingComponent: boolean
}

const initialState: ComponentState = {
	components: [],
	errorOneComponent: null,
	loadingComponent: false,
}

export const fetchComponentsThunk = createAsyncThunk(
	'component/fetchComponents',
	async (data: number[], { rejectWithValue }) => {
		const results: FormData[] = []

		// Проходим последовательно по каждому элементу
		for (const id of data) {
			try {
				// Делаем запрос на сервер для текущего id

				const response = await axios.get(
					`${process.env.REACT_APP_SERVER}/websiteGenerator/component/get?componentId=${id}`
				)

				// Если запрос успешен, добавляем результат в массив
				results.push(response.data)
			} catch (errorPOSTWebsite: any) {
				// Если ошибка, переходим к следующему элементу
				// Сохраняем ошибку, если нужно

				return results
			}
		}

		// Возвращаем все успешные результаты
		return results
	}
)

export const componentFetchGetOneSlice = createSlice({
	name: 'template',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchComponentsThunk.pending, state => {
				state.loadingComponent = true
				state.errorOneComponent = null
				state.components = [] // Очищаем предыдущие данные
			})
			.addCase(
				fetchComponentsThunk.fulfilled,
				(state, action: PayloadAction<FormData[]>) => {
					state.loadingComponent = false
					state.components = action.payload // Сохраняем все полученные компоненты
				}
			)
			.addCase(
				fetchComponentsThunk.rejected,
				(state, action: PayloadAction<any>) => {
					state.loadingComponent = false

					state.errorOneComponent = action.payload // Сохраняем сообщение об ошибке
				}
			)
	},
})

export const loadingComponentData = (state: RootState) =>
	state.componentFetchGetOneSlice.loadingComponent
export const errorOneComponentData = (state: RootState) =>
	state.componentFetchGetOneSlice.errorOneComponent
export const componentsData = (state: RootState) =>
	state.componentFetchGetOneSlice.components

export default componentFetchGetOneSlice.reducer
