import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { templateCreate } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface templateGetOne extends templateCreate {
    id: number
    createdAt: string
    updatedAt: string
    components: number[]
}
interface templateLocalProps {
    templateId: number
    errorGetOneTemplate: null | string
    loadingGetOneTemplate: boolean
    template: templateGetOne
}
const initialState: templateLocalProps = {
    templateId: 0,
    errorGetOneTemplate: null,
    loadingGetOneTemplate: false,
    template: {
        id: 0,
        name: '',
        components: [],
        createdAt: '',
        updatedAt: '',
    },
}

export const fetchOneSecondTemplate = createAsyncThunk(
    'templatePrev/templateGetOnePrev',
    async (data: number, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/get?templateId=${data}`
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchGetPrevOneSlice = createSlice({
    name: 'templatePrev',
    initialState,
    reducers: {
        setClearDataTemplatePrev: (state) => {
            state.template = {
                id: 0,
                name: '',
                components: [],
                createdAt: '',
                updatedAt: '',
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOneSecondTemplate.pending, (state) => {
                state.loadingGetOneTemplate = true
                state.errorGetOneTemplate = null
            })
            .addCase(
                fetchOneSecondTemplate.fulfilled,
                (state, action: PayloadAction<templateGetOne[]>) => {
                    state.loadingGetOneTemplate = false
                    state.template = action.payload[0]
                }
            )
            .addCase(
                fetchOneSecondTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingGetOneTemplate = false
                    state.errorGetOneTemplate = action.payload
                }
            )
    },
})
export const { setClearDataTemplatePrev } = templateFetchGetPrevOneSlice.actions
export const loadingGetPrevOneTemplateData = (state: RootState) =>
    state.templateFetchGetPrevOneSlice.loadingGetOneTemplate
export const errorGetOnePrevTemplateData = (state: RootState) =>
    state.templateFetchGetPrevOneSlice.errorGetOneTemplate
export const templateGetOnePrevData = (state: RootState) =>
    state.templateFetchGetPrevOneSlice.template
export default templateFetchGetPrevOneSlice.reducer
