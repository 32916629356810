import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../../app/redux/store'

interface createWebsiteProps {
    id: number
    loadingWebsiteDelete: boolean
    errorDeleteWebsite: string | null
}

const initialState: createWebsiteProps = {
    id: 0,
    loadingWebsiteDelete: false,
    errorDeleteWebsite: null,
}

export const fetchDeleteWebsite = createAsyncThunk(
    'website/deleteWebsite',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/website/delete?id=${id}`
            )

            return response.data
        } catch (errorDeleteWebsite: any) {
            return rejectWithValue(errorDeleteWebsite.message)
        }
    }
)

export const webSiteDeleteSlice = createSlice({
    name: 'websiteDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteWebsite.pending, (state) => {
                state.loadingWebsiteDelete = true
                state.errorDeleteWebsite = null
            })
            .addCase(
                fetchDeleteWebsite.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingWebsiteDelete = false
                    state.id = action.payload
                }
            )
            .addCase(
                fetchDeleteWebsite.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingWebsiteDelete = false
                    state.errorDeleteWebsite = action.payload
                }
            )
    },
})

export const loadingWebsiteDelete = (state: RootState) =>
    state.webSiteDeleteSlice.loadingWebsiteDelete
export const errorDeleteWebsite = (state: RootState) =>
    state.webSiteDeleteSlice.errorDeleteWebsite
export const idDeleteWebSite = (state: RootState) => state.webSiteDeleteSlice.id
export default webSiteDeleteSlice.reducer
