import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from './../../../app/redux/store'

import { VerticalProps } from '../../../shared/types/types'

interface VerticalLocal {
    vertical: VerticalProps[]
    errorVertical: null | string
    loadingVertical: boolean
}
const initialState: VerticalLocal = {
    vertical: [
        {
            id: 0,
            vertical: '',
            createdAt: '',
            updatedAt: '',
        },
    ],
    errorVertical: null,
    loadingVertical: false,
}

export const fetchVertical = createAsyncThunk(
    'vertical/verticalGet',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/vertical/get`
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const verticalGetSlice = createSlice({
    name: 'verticalCurrent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVertical.pending, (state) => {
                state.loadingVertical = true
                state.errorVertical = null
            })
            .addCase(
                fetchVertical.fulfilled,
                (state, action: PayloadAction<VerticalProps[]>) => {
                    state.loadingVertical = false
                    state.vertical = action.payload
                }
            )
            .addCase(
                fetchVertical.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingVertical = false
                    state.errorVertical = action.payload
                }
            )
    },
})

export const loadingVertical = (state: RootState) =>
    state.verticalGetSlice.loadingVertical
export const errorVertical = (state: RootState) =>
    state.verticalGetSlice.errorVertical
export const vertical = (state: RootState) => state.verticalGetSlice.vertical
export default verticalGetSlice.reducer
