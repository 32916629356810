import Input from '@mui/joy/Input'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Editor from '@monaco-editor/react'
import { StyledAndLinkData } from '../../app/redux/slices/component/styleLinksAndScriptComponentSlice'
import {
	dataGroup,
	errorGetGroup,
	fetchGetGroups,
	loadingGetGroup,
} from '../../app/redux/slices/group/getGroupSlice'
import { setCurrentOpen } from '../../app/redux/slices/isOpenSlice'
import { AppDispatch } from '../../app/redux/store'
import { errorCreateComponent } from '../../entities/component/api/componentCreateSlice'
import { errorUpdateComponent } from '../../entities/component/api/componentUpdateSlice'
import { createAndSeeNewComponentSlice } from '../../entities/component/api/thunkCreateComponentSlice'
import { updateAndSeeNewComponentSlice } from '../../entities/component/api/thunkUpdateComponetSlice'
import { objectComponentCreate } from '../../entities/component/model'
import { objectComponents } from '../../entities/component/model/index'
import { GroupProps } from '../../shared/types/types'
import FreeSoloCreateOption from '../../shared/ui/Creatable'
import styles from './Popup.module.scss'

const Popup: React.FC<objectComponents> = ({
	name,
	html,
	id,
	group,
	links,
}) => {
	const dispatch = useDispatch<AppDispatch>()

	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const [localContent, setLocalContent] = useState<string>('')
	const dataGroupLocal = useSelector(dataGroup)
	const loadingGetGroupLocal = useSelector(loadingGetGroup)
	const errorGetGroupLocal = useSelector(errorGetGroup)
	const [title, setTitle] = useState<string>('')
	const [nameComponent, setNameComponent] = useState<string | null>(null)
	const errorComponent = useSelector(errorCreateComponent)
	const errorUpdComponent = useSelector(errorUpdateComponent)
	const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
	const someObjectLocal = useSelector(StyledAndLinkData)
	const [isUpdate, setIsUpdate] = useState<boolean>(false)

	useEffect(() => {
		setNameComponent(name)
	}, [name])
	useEffect(() => {
		if (loadingGetGroupLocal === false && errorGetGroupLocal === null) {
			dataGroupLocal.map((el: GroupProps) => {
				if (el.id === group) {
					setSelectedGroup(el.nameGroup)
				}
			}, [])
		}
	}, [dataGroupLocal, id])
	useEffect(() => {
		if (isUpdate) {
			return
		}

		const cleanLocalContent = html
			? html
			: localContent
					.replace(/<link .*?>/g, '') // Убираем все теги <link> из localContent
					.replace(/<script .*?>.*?<\/script>/g, '') // Убираем все теги <script> из localContent
					.replace(
						/<div\s+class=["']parent-wrapper["'][^>]*>|<\/div\s*>/g,
						(match, offset, string) => {
							const lastIndex = string.lastIndexOf(match)
							return offset === lastIndex ? '' : match
						}
					)
					.replace(/^\s*[\r\n]/gm, '') // Убираем пустые строки
		const contentWithWrapper = `
  <div class="parent-wrapper">
    ${someObjectLocal.styleLinks?.map((link: string) => `<link rel="stylesheet" href="${link}" />`).join('')}
    ${cleanLocalContent}
    ${someObjectLocal.scriptLinks?.map((script: string) => `<script src="${script}"></script>`).join('')}
  </div>
`

		setLocalContent(contentWithWrapper)
	}, [html, someObjectLocal])

	useEffect(() => {
		setLocalContent(html)
	}, [html])
	const handleOpen = () => {
		dispatch(setCurrentOpen({ id, content: html, stateOpen: false }))
	}
	type objectComponentUpdate = Omit<objectComponents, 'createdAt' | 'updatedAt'>
	const handleOnChange = (value: string | undefined, monaco: any) => {
		if (value !== undefined) {
			setLocalContent(value)
			let stateOpen = true
			//monaco.languages.html.setEagerModelSync(true);
			dispatch(setCurrentOpen({ id, content: value, stateOpen }))
		}
	}

	const handleChangeTitle = (e: any) => {
		setTitle(e.target.value + '')
	}

	useEffect(() => {
		dispatch(fetchGetGroups())
	}, [])
	const handleUpdate = () => {
		setIsUpdate(true)
		const linksStyle = someObjectLocal.styleLinks
			? someObjectLocal.styleLinks
			: []
		const linksScript = someObjectLocal.scriptLinks
			? someObjectLocal.scriptLinks
			: []
		const cleanLocalContent = localContent
			.replace(/<link .*?>/g, '') // Убираем все теги <link> из localContent
			.replace(/<script .*?>.*?<\/script>/g, '') // Убираем все теги <script> из localContent
			.replace(
				/<div\s+class=["']parent-wrapper["'][^>]*>|<\/div\s*>/g,
				(match, offset, string) => {
					const lastIndex = string.lastIndexOf(match)
					return offset === lastIndex ? '' : match
				}
			)
			.replace(/^\s*[\r\n]/gm, '') // Убираем пустые строки

		let updateObjectComponent: objectComponentUpdate = {
			id,
			html: cleanLocalContent,
			group: someObjectLocal.group,
			name: nameComponent + '',
			links: {
				styleLinks: [...linksStyle],
				scriptLinks: [...linksScript],
			},
		}
		//setLocalContent(localContent)

		setIsUpdate(false)
		dispatchThunk(updateAndSeeNewComponentSlice(updateObjectComponent))
	}
	const handleCreate = () => {
		const linksStyle = someObjectLocal.styleLinks
			? someObjectLocal.styleLinks
			: []
		const linksScript = someObjectLocal.scriptLinks
			? someObjectLocal.scriptLinks
			: []
		const cleanLocalContent = localContent
			.replace(/<link .*?>/g, '') // Убираем все теги <link> из localContent
			.replace(/<script .*?>.*?<\/script>/g, '') // Убираем все теги <script> из localContent
			.replace(
				/<div\s+class=["']parent-wrapper["'][^>]*>|<\/div\s*>/g,
				(match, offset, string) => {
					const lastIndex = string.lastIndexOf(match)
					return offset === lastIndex ? '' : match
				}
			)
			.replace(/^\s*[\r\n]/gm, '') // Убираем пустые строки
		let createObjectComponent: objectComponentCreate = {
			html: cleanLocalContent,
			group: someObjectLocal.group,
			name: nameComponent + '',
			links: {
				styleLinks: [...linksStyle],
				scriptLinks: [...linksScript],
			},
		}

		dispatchThunk(createAndSeeNewComponentSlice(createObjectComponent))
		dispatch(
			setCurrentOpen({ id, content: cleanLocalContent, stateOpen: false })
		)
	}

	useEffect(() => {
		if (errorComponent) {
			alert(errorComponent)
		}
	}, [errorComponent])
	useEffect(() => {
		if (errorUpdComponent) {
			alert(errorUpdComponent)
		}
	}, [errorUpdComponent])
	const handleChangeName = (e: any) => {
		setNameComponent(e.target.value.toString())
	}
	const codeEditor = useRef<HTMLTextAreaElement>(null)

	return (
		<div className={styles.wrapperPopup}>
			<div>
				{html && (
					<button className={styles.closeBtn} onClick={handleOpen}></button>
				)}
				{html && (
					<button className={styles.updateBtn} onClick={handleUpdate}></button>
				)}
				{!html && (
					<button className={styles.createBtn} onClick={handleCreate}></button>
				)}
			</div>
			<div className={styles.wrapperName}>имя</div>
			<Input
				placeholder='напишите имя'
				value={nameComponent?.toString()}
				onChange={handleChangeName}
			/>
			<Editor
				height='90vh'
				defaultLanguage='html'
				value={localContent}
				onChange={handleOnChange}
			/>
			{/* <textarea ref={codeEditor} className={styles.inputContent} onChange={handleOnChange} value={localContent} /> */}

			<div className={styles.btnsWrapper}>
				{someObjectLocal && (
					<>
						{errorGetGroupLocal === null && loadingGetGroupLocal === false && (
							<FreeSoloCreateOption
								id={id}
								list={dataGroupLocal}
								defaultGroup={group}
								type={'group'}
							/>
						)}
						<FreeSoloCreateOption id={id} links={links} type={'style'} />
						<FreeSoloCreateOption id={id} links={links} type={'script'} />
					</>
				)}
			</div>
		</div>
	)
}

export default Popup
