import { Modal as BaseModal } from '@mui/base/Modal'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { css, styled } from '@mui/system'
import clsx from 'clsx'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentData } from '../../../app/redux/slices/currentDataSlice'
import { dataComponents } from '../../component/api/componentsSlice'

import {
    errorTemplate,
    loadingTemplate,
    template,
} from '../../../app/redux/slices/template/templateGetSlice'
import {
    errorGetOneTemplateData,
    fetchOneTemplate,
    loadingGetOneTemplateData,
    templateGetOneData,
} from '../../../app/redux/slices/template/templateOneGetSlice'
import {
    errorGetOnePrevTemplateData,
    fetchOnePrevTemplate,
    loadingGetOnePrevTemplateData,
    templateGetPrevOneData,
} from '../../../app/redux/slices/template/templateOnePrevGetSlice'
import { AppDispatch } from '../../../app/redux/store'
import { combineHTML } from '../../../features/combineHtml'
import { findComponents } from '../../../features/findComponents'
import ResponsiveModal from '../../../shared/ui/Alert'
import { setCurrentWebSite } from '../api/currentWebsiteUpdateSlice'
import { setCurrentEditorOpen } from '../api/isOpenEditorSlice'
import { websiteNewProps } from '../model/index'

interface CartProps {
    wrapper: string
    id: number
    name: string
    geoId: number
    verticalId: number
    templateId: number
}

const CardSite: React.FC<CartProps> = ({
    wrapper,
    id,
    name,
    geoId,
    verticalId,
    templateId,
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch<AppDispatch>()
    const iframeRef = React.useRef<HTMLIFrameElement>(null)

    const handleClose = () => setOpen(false)

    const loadingGetOneTemplateDataLocal = useSelector(
        loadingGetOneTemplateData
    )
    const errorGetOneTemplateDataLocal = useSelector(errorGetOneTemplateData)
    const templateGetOneDataLocal = useSelector(templateGetOneData)
    const dataComponentsLocal = useSelector(dataComponents)
    const loadingTemplateLocal = useSelector(loadingTemplate)
    const errorTemplateLocal = useSelector(errorTemplate)
    const templateLocal = useSelector(template)
    const loadingGetOnePrevTemplateDataLocal = useSelector(
        loadingGetOnePrevTemplateData
    )
    const errorGetOnePrevTemplateDataLocal = useSelector(
        errorGetOnePrevTemplateData
    )
    const templateGetPrevOneDataLocal = useSelector(templateGetPrevOneData)

    const handleOpen = () => {
        dispatch(fetchOnePrevTemplate(templateId))
        setOpen(true)
    }
    React.useEffect(() => {
        if (
            templateGetOneDataLocal.id !== 0 &&
            errorGetOneTemplateDataLocal === null &&
            loadingGetOneTemplateDataLocal === false
        ) {
            const dataIds: number[] = templateGetOneDataLocal.components
                ? templateGetOneDataLocal.components
                : []
            const result = findComponents(dataIds, dataComponentsLocal)
            // const content = combineHTML(result);
            let content = result
                .map((item: { html: string }) => item.html)
                .join('')

            const resultLinks = result.map((item) => item.links)
            const scriptLinks: string[] = []
            const styleLinks: string[] = []

            resultLinks.forEach((links) => {
                if (links.scriptLinks) {
                    scriptLinks.push(...links.scriptLinks)
                }
                if (links.styleLinks) {
                    styleLinks.push(...links.styleLinks)
                }
            })

            let contentWithLinks = '<div>'
            styleLinks.forEach((link) => {
                contentWithLinks += `<link rel="stylesheet" href="${link}">`
            })
            scriptLinks.forEach((link) => {
                contentWithLinks += `<script src="${link}"></script>`
            })
            contentWithLinks += content
            contentWithLinks += '</div>'
            dispatch(setCurrentData(contentWithLinks))
        }
    }, [
        errorGetOneTemplateDataLocal,
        loadingGetOneTemplateDataLocal,
        templateGetOneDataLocal,
    ])
    React.useEffect(() => {
        setTimeout(()=>{
            if (
                templateGetPrevOneDataLocal.id !== 0 &&
                errorGetOnePrevTemplateDataLocal === null &&
                loadingGetOnePrevTemplateDataLocal === false
            ) {
            
                const dataIds: number[] =
                    templateGetPrevOneDataLocal.components || []
                const result = findComponents(dataIds, dataComponentsLocal)
                const content = combineHTML(result)
    
                const resultLinks = result.map((item) => item.links)
                const scriptLinks: string[] = []
                const styleLinks: string[] = []
    
                resultLinks.forEach((links) => {
                    if (links.scriptLinks) {
                        scriptLinks.push(...links.scriptLinks)
                    }
                    if (links.styleLinks) {
                        styleLinks.push(...links.styleLinks)
                    }
                })
    
                const injectContent = () => {
                    if (iframeRef.current?.contentDocument) {
                        const iframeDoc = iframeRef.current.contentDocument
    
                        styleLinks.forEach((link) => {
                            const styleElement = iframeDoc.createElement('link')
                            styleElement.rel = 'stylesheet'
                            styleElement.href = link
                            iframeDoc.head.appendChild(styleElement)
                        })
                       
                        iframeDoc.body.innerHTML = content
    
                        scriptLinks.forEach((link) => {
                            const scriptElement = iframeDoc.createElement('script')
                            scriptElement.src = link
                            scriptElement.async = true
                            iframeDoc.body.appendChild(scriptElement)
                        })
                    }
                }
    
                if (iframeRef.current) {
                
                    injectContent()
                }
            }
        },500)
    }, [
        templateGetPrevOneDataLocal,
        loadingGetOnePrevTemplateDataLocal,
        errorGetOnePrevTemplateDataLocal,
    ])

    const updateWeb = () => {
        let newObject: websiteNewProps = {
            id,
            name,
            templateId: templateId,
            wrapper: '',
            geoId: geoId,
            affiliateProgramId: 1,
            verticalId: verticalId,
        }

        dispatch(setCurrentWebSite(newObject))
        dispatch(fetchOneTemplate(templateId))
        dispatch(setCurrentEditorOpen(true))
    }

    const downloadHTML = () => {
        if (iframeRef.current?.contentDocument) {
            const iframeDoc = iframeRef.current.contentDocument
            const htmlContent = iframeDoc.documentElement.outerHTML
            const blob = new Blob([htmlContent], { type: 'text/html' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `${name}.html`
            a.click()
            URL.revokeObjectURL(url)
        }
    }

    return (
        <Card sx={{ minWidth: 275, margin: 1 }}>
            <CardContent>
                {/* <Typography variant='h5' component='div'>
                    {id}
                </Typography> */}
                <Typography variant='h5' component='div'>
                    {name}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={handleOpen} size='small'>
                    увидеть превью
                </Button>
                <Button onClick={updateWeb} size='small'>
                    изменить
                </Button>
                <ResponsiveModal
                    typeContent={'website'}
                    type={'delete'}
                    idWebsite={id}
                    content={'Вы действительно хотите удалить веб-сайт?'}
                />
            </CardActions>
            <Modal
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
                aria-labelledby='unstyled-modal-title'
                aria-describedby='unstyled-modal-description'
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}>
                <ModalContent sx={{ width: '50vw', height: '80vh' }}>
                    <h2 id='unstyled-modal-title' className='modal-title'>
                        {name}
                    </h2>
                    <iframe
                        ref={iframeRef}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                        title='Preview'
                    />
                    <Button onClick={downloadHTML} size='small'>
                        Скачать HTML
                    </Button>
                </ModalContent>
            </Modal>
        </Card>
    )
}
export default CardSite

const Backdrop = React.forwardRef<
    HTMLDivElement,
    { open?: boolean; className: string }
>((props, ref) => {
    const { open, className, ...other } = props
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    )
})

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
}

const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    overflow: scroll;
`

export const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
`

export const ModalContent = styled('div')(
    ({ theme }) => css`
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 500;
        text-align: start;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;

        background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border-radius: 8px;
        border: 1px solid
            ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0 4px 12px
            ${theme.palette.mode === 'dark'
                ? 'rgb(0 0 0 / 0.5)'
                : 'rgb(0 0 0 / 0.2)'};
        padding: 24px;
        color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

        & .modal-title {
            margin: 0;
            line-height: 1.5rem;
            margin-bottom: 8px;
        }

        & .modal-description {
            margin: 0;
            line-height: 1.5rem;
            font-weight: 400;
            color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
            margin-bottom: 4px;
        }
    `
)

const TriggerButton = styled('button')(
    ({ theme }) => css`
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 8px;
        transition: all 150ms ease;
        cursor: pointer;
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid
            ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

        &:hover {
            background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
            border-color: ${theme.palette.mode === 'dark'
                ? grey[600]
                : grey[300]};
        }

        &:active {
            background: ${theme.palette.mode === 'dark'
                ? grey[700]
                : grey[100]};
        }

        &:focus-visible {
            box-shadow: 0 0 0 4px
                ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
            outline: none;
        }
    `
)
