import { objectComponentCreate } from '../model/index'
import { RootState } from './../../../app/redux/store'
import { objectComponents } from './../model/index'

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

interface updateComponent {
    dataCreateObject: objectComponents
    loadingCreateComponent: boolean
    errorCreateComponent: string | null
}

const initialState: updateComponent = {
    dataCreateObject: {
        id: 0,
        html: '',
        name: '',
        group: 1,
        links: {
            styleLinks: [''],
            scriptLinks: [''],
        },
        updatedAt: '',
        createdAt: '',
    },
    loadingCreateComponent: false,
    errorCreateComponent: null,
}

export const fetchCreateComponent = createAsyncThunk(
    'component/createObject',
    async (data: objectComponentCreate, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/component/create`,
                {
                    ...data,
                }
            )

            return response.data
        } catch (errorCreateComponent: any) {
            return rejectWithValue(errorCreateComponent.message)
        }
    }
)

export const componentCreateSlice = createSlice({
    name: 'componentCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreateComponent.pending, (state) => {
                state.loadingCreateComponent = true
                state.errorCreateComponent = null
            })
            .addCase(
                fetchCreateComponent.fulfilled,
                (state, action: PayloadAction<objectComponents>) => {
                    state.loadingCreateComponent = false
                    state.dataCreateObject = action.payload
                }
            )
            .addCase(
                fetchCreateComponent.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingCreateComponent = false
                    state.errorCreateComponent = action.payload
                }
            )
    },
})
export const loadingCreateComponent = (state: RootState) =>
    state.componentCreateSlice.loadingCreateComponent
export const dataCreateObject = (state: RootState) =>
    state.componentCreateSlice.dataCreateObject
export const errorCreateComponent = (state: RootState) =>
    state.componentCreateSlice.errorCreateComponent

export default componentCreateSlice.reducer
