import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { templateCreate } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface templateUpdateOne extends Omit<templateCreate, 'name'> {
    templateId: number
    components: number[]
    templateName: string
}
interface templateLocalProps {
    errorDeleteOneTemplate: null | string
    loadingDeleteOneTemplate: boolean
    template: templateUpdateOne
}
const initialState: templateLocalProps = {
    errorDeleteOneTemplate: null,
    loadingDeleteOneTemplate: false,
    template: {
        templateId: 0,
        templateName: '',
        components: [],
    },
}

export const fetchDeleteTemplate = createAsyncThunk(
    'template/templateDeleteOne',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/delete?id=${id}`
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchDeleteOneSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteTemplate.pending, (state) => {
                state.loadingDeleteOneTemplate = true
                state.errorDeleteOneTemplate = null
            })
            .addCase(
                fetchDeleteTemplate.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingDeleteOneTemplate = false
                    //state.template = action.payload;
                }
            )
            .addCase(
                fetchDeleteTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingDeleteOneTemplate = false
                    state.errorDeleteOneTemplate = action.payload
                }
            )
    },
})

export const loadingDeleteOneTemplateData = (state: RootState) =>
    state.templateFetchDeleteOneSlice.loadingDeleteOneTemplate
export const errorDeleteOneTemplateData = (state: RootState) =>
    state.templateFetchDeleteOneSlice.errorDeleteOneTemplate
export default templateFetchDeleteOneSlice.reducer
