import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    dataGroup,
    errorGetGroup,
    fetchGetGroups,
    loadingGetGroup,
} from '../../app/redux/slices/group/getGroupSlice'
import { setCurrentOpen } from '../../app/redux/slices/isOpenSlice'
import { AppDispatch } from '../../app/redux/store'
import {
    dataComponents,
    errorComponents,
    fetchComponents,
    loadingComponents,
} from '../../entities/component/api/componentsSlice'
import { objectComponents } from '../../entities/component/model'
import CardComponent from '../../entities/component/ui/CardComponent'
import { GroupProps } from '../../shared/types/types'
import Popup from '../Popup/Popup'
import styles from './Components.module.scss'
import PreviewComponent from './PreviewComponent'
const Components = () => {
    const [currentContent, setCurrentContent] = useState<string>('')
    const [currentComponent, setCurrentComponent] = useState<objectComponents>({
        id: 0,
        html: '',
        name: '',
        group: 0,
        links: {
            scriptLinks: [''],
            styleLinks: [''],
        },
        createdAt: '',
        updatedAt: '',
    })
    const [open, setOpen] = useState(false)
    const [currentName, setCurrentName] = useState<string>('')
    const dataGroupLocal = useSelector(dataGroup)
    const loadingGetGroupLocal = useSelector(loadingGetGroup)
    const errorGetGroupLocal = useSelector(errorGetGroup)
    const [localId, setLocalId] = useState<number | null>(null)
    const { stateOpen, id } = useSelector((state: any) => state.isOpenSlice)
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(fetchComponents())
        dispatch(fetchGetGroups())
    }, [])
    const loadingComp = useSelector(loadingComponents)
    const dataComp = useSelector(dataComponents)
    const errorComp = useSelector(errorComponents)
    useEffect(() => {
        loadingComp === false &&
            errorComp === null &&
            dataComp.map((element: objectComponents) => {
                if (element.id === id) {
                    setCurrentComponent(element)
                    setCurrentName(element.name + '')
                    let combinedContent = '<div class="parent-wrapper">'

                    // Добавьте styleLinks в combinedContent
                    element.links.styleLinks.forEach((link) => {
                        combinedContent += `<link rel="stylesheet" href="${link}">`
                    })

                    // Добавьте scriptLinks в combinedContent
                    element.links.scriptLinks.forEach((link) => {
                        combinedContent += `<script src="${link}"></script>`
                    })

                    // Добавьте основное HTML-содержимое
                    combinedContent += element.html

                    // Закройте div
                    combinedContent += '</div>'

                    // Установите содержимое

                    setCurrentContent(combinedContent)
                }
            })
    }, [loadingComp, id])

    const handleOpenNewComponent = () => {
        setOpen(true)
    }
    const filteredComponentsByGroup = (
        arr: objectComponents[],
        groupId: number
    ): objectComponents[] => {
        let newArr = arr.filter((el: objectComponents) => {
            if (el.group === groupId) {
                return el
            }
        })

        return newArr
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Stack direction='column' spacing={2} sx={{ mr: '7px' }}>
                    <Button
                        variant='contained'
                        size='medium'
                        onClick={handleOpenNewComponent}>
                        Добавить компонент
                    </Button>
                </Stack>
                {dataComp.length > 0 &&
                loadingGetGroupLocal === false &&
                errorGetGroupLocal === null ? (
                    dataGroupLocal.map((group: GroupProps) => {
                        return (
                            <Grid item xs={6} key={group.id}>
                                <div className={styles.wrapperNameGroup}>
                                    {group.nameGroup}
                                </div>
                                {dataComp.length > 0 ? (
                                    filteredComponentsByGroup(
                                        dataComp,
                                        group.id
                                    ).map((el: any) => {
                                        return (
                                            <CardComponent
                                                key={el.id}
                                                {...el}
                                            />
                                        )
                                    })
                                ) : (
                                    <>loading component</>
                                )}
                            </Grid>
                        )
                    })
                ) : (
                    <>loading</>
                )}
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault()
                        const formData = new FormData(event.currentTarget)
                        const formJson = Object.fromEntries(formData.entries())
                        const nameComp: string = formJson.nameComp.toString()

                        const newObject: objectComponents = {
                            id: 0,
                            html: '',
                            // name: some,
                            name: nameComp,
                            group: 0,
                            links: {
                                scriptLinks: [''],
                                styleLinks: [''],
                            },
                            createdAt: '',
                            updatedAt: '',
                        }
                        setCurrentComponent(newObject)
                        setCurrentName(nameComp)
                        setCurrentContent('')

                        let maxId: any = dataComp.reduce(
                            (acc: any, curr: any) => {
                                return acc.id > curr.id ? acc : curr
                            },
                            { id: -Infinity }
                        )
                        setLocalId(maxId.id + 1)
                        let stateOpen = true
                        dispatch(setCurrentOpen({ id, content: '', stateOpen }))
                        handleClose()
                    },
                }}>
                <DialogTitle>Добавить компонент</DialogTitle>
                <DialogContent>
                    <DialogContentText>Введите название:</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='nameComp'
                        name='nameComp'
                        label='name'
                        type='text'
                        fullWidth
                        variant='standard'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button type='submit'>Добавить</Button>
                </DialogActions>
            </Dialog>
            <div className={styles.innerComponentAndPreview}>
                {currentComponent && stateOpen === true && (
                    <Popup {...currentComponent} />
                )}

                {stateOpen === true && (
                    <PreviewComponent html={currentContent} />
                )}
            </div>
        </Box>
    )
}

export default Components
