import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../store'

interface templateFetched {
    id: null | number
    name: string
    updatedAt: string
    createdAt: string
}

interface templateLocalProps {
    templateFetched: templateFetched
    errorCreateTemplate: null | string
    loadingCreateTemplate: boolean
}

const initialState: templateLocalProps = {
    templateFetched: {
        id: null,
        name: '',
        updatedAt: '',
        createdAt: '',
    },
    errorCreateTemplate: null,
    loadingCreateTemplate: false,
}

export const fetchCreateTemplate = createAsyncThunk(
    'template/templateCreate',
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/create`,
                {
                    ...data,
                }
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchCreateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreateTemplate.pending, (state) => {
                state.loadingCreateTemplate = true
                state.errorCreateTemplate = null
            })
            .addCase(
                fetchCreateTemplate.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingCreateTemplate = false
                    state.templateFetched = action.payload
                }
            )
            .addCase(
                fetchCreateTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingCreateTemplate = false
                    state.errorCreateTemplate = action.payload
                }
            )
    },
})

export const loadingCreateTemplate = (state: RootState) =>
    state.templateFetchCreateSlice.loadingCreateTemplate
export const errorCreateTemplate = (state: RootState) =>
    state.templateFetchCreateSlice.errorCreateTemplate
export const templateCreateData = (state: RootState) =>
    state.templateFetchCreateSlice.templateFetched
export default templateFetchCreateSlice.reducer
