import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { setCurrentData } from '../../../app/redux/slices/currentDataSlice'
import { setDeleteCurrentGeo } from '../../geo/api/currentGeoSlice'

import { setCurrentDeleteTemplate } from '../../../app/redux/slices/template/currentTemplateSlice'
import { setClearDataTemplate } from '../../../app/redux/slices/template/templateOneGetSlice'

import { websiteNewProps } from '../../../shared/types/types'
import { setDeleteCurrentVertical } from '../../vertical/api/currentVerticalSlice'
import { fetchUpdateWebsite, seCLearWebSite } from './currentWebsiteUpdateSlice'
import { setCurrentEditorOpen } from './isOpenEditorSlice'
import { fetchGetWebSite } from './websiteGetSlice'

export const updateAndSeeNewWebsiteSlice =
	(someObject: websiteNewProps) =>
	async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		try {
			await dispatch(fetchUpdateWebsite(someObject)).unwrap()
			await dispatch(fetchGetWebSite()).unwrap()
			dispatch(setCurrentDeleteTemplate())
			dispatch(setDeleteCurrentGeo())
			dispatch(setDeleteCurrentVertical())
			dispatch(setCurrentData(''))
			dispatch(setClearDataTemplate())
			dispatch(setCurrentEditorOpen(false))
			dispatch(seCLearWebSite())
			window.location.reload()
		} catch (error) {
			console.error('Ошибка выполнения:', error)
		}
	}
