import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../store'
interface componentProps {
    loadingGetGroup: boolean
    errorGetGroup: string | null
}
const initialState: componentProps = {
    loadingGetGroup: true,
    errorGetGroup: null,
}
export const fetchDeleteGroups = createAsyncThunk(
    'group/deleteGroup',
    async (groupId: number, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/componentGroup/delete`,
                {
                    params: {
                        groupId,
                    },
                }
            )
            return response.data
        } catch (errorUpdateComponent: any) {
            return rejectWithValue(errorUpdateComponent.message)
        }
    }
)
export const groupDeleteSlice = createSlice({
    name: 'groupDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteGroups.pending, (state) => {
                state.loadingGetGroup = true
                state.errorGetGroup = null
            })
            .addCase(
                fetchDeleteGroups.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingGetGroup = false
                }
            )
            .addCase(
                fetchDeleteGroups.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingGetGroup = false
                    state.errorGetGroup = action.payload
                }
            )
    },
})
export const loadingDeleteGroup = (state: RootState) =>
    state.groupDeleteSlice.loadingGetGroup
export const errorDeleteGroup = (state: RootState) =>
    state.groupDeleteSlice.errorGetGroup

export default groupDeleteSlice.reducer
