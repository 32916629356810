import React, { useEffect, useRef } from 'react'

interface PreviewComponentProps {
    html: string
}

const PreviewComponent: React.FC<PreviewComponentProps> = ({ html }) => {
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = containerRef.current
        if (element) {
            // Check if the shadow root already exists
            let shadowRoot = element.shadowRoot
            if (!shadowRoot) {
                shadowRoot = element.attachShadow({ mode: 'open' })
            }
            // Update shadow root content
            shadowRoot.innerHTML = html
        }
    }, [html])

    return <div ref={containerRef} />
}

export default PreviewComponent
