import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import * as React from 'react'

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { setIsUpdateTemp } from '../../app/redux/slices/template/isUpdateEventSlice'
import { fetchCreateTemplate } from '../../app/redux/slices/template/templateCreateSlice'
import { setClearDataTemplate } from '../../app/redux/slices/template/templateOneGetSlice'

export default function FormDialog() {
    const [open, setOpen] = React.useState(false)
    const dispatch = useDispatch()
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const someHandle = (data: string) => {
        dispatch(
            setIsUpdateTemp({
                id: null,
                state: false,
                name: '',
                components: [],
            })
        )
        dispatch(setClearDataTemplate())
        dispatchThunk(fetchCreateTemplate({ name: data }))
    }
    return (
        <React.Fragment>
            <Button variant='outlined' onClick={handleClickOpen}>
                Создать шаблон
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault() // Предотвращаем стандартное поведение отправки формы
                        const formData = new FormData(event.currentTarget) // Создаем объект FormData из формы
                        const formJson = Object.fromEntries(formData.entries()) // Преобразуем FormData в JSON-объект

                        const name = formJson.text.toString()
                        someHandle(name)
                        handleClose() // Вызываем функцию handleClose
                    },
                }}>
                <DialogTitle>Шаблон</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите название для создания шаблона
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='name'
                        name='text'
                        label='text'
                        type='text'
                        fullWidth
                        variant='standard'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button type='submit'>Добавить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
