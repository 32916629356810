import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { GroupProps } from '../../../../shared/types/types'
import { RootState } from '../../store'
interface componentProps {
    dataGroup: GroupProps[]
    loadingGetGroup: boolean
    errorGetGroup: string | null
}
const initialState: componentProps = {
    dataGroup: [],
    loadingGetGroup: true,
    errorGetGroup: null,
}
export const fetchGetGroups = createAsyncThunk(
    'group/getGroup',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/componentGroup/get`
            )

            // Transform the response data to match GroupProps
            const transformedData = response.data.map((group: any) => ({
                id: group.id,
                nameGroup: group.name, // Transforming 'name' to 'nameGroup'
                createdAt: group.createdAt,
                updatedAt: group.updatedAt,
            }))

            return transformedData
        } catch (errorUpdateComponent: any) {
            return rejectWithValue(errorUpdateComponent.message)
        }
    }
)
export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetGroups.pending, (state) => {
                state.loadingGetGroup = true
                state.errorGetGroup = null
            })
            .addCase(
                fetchGetGroups.fulfilled,
                (state, action: PayloadAction<GroupProps[]>) => {
                    state.loadingGetGroup = false
                    state.dataGroup = action.payload
                }
            )
            .addCase(
                fetchGetGroups.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingGetGroup = false
                    state.errorGetGroup = action.payload
                }
            )
    },
})
export const loadingGetGroup = (state: RootState) =>
    state.groupSlice.loadingGetGroup
export const dataGroup = (state: RootState) => state.groupSlice.dataGroup
export const errorGetGroup = (state: RootState) =>
    state.groupSlice.errorGetGroup

export default groupSlice.reducer
